// scss-lint:disable SelectorFormat

@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.sidebar {
  background-color: $brand-primary;
  background-image: url(../../assets/svgs/sidebar-bg.svg);
  background-size: cover;
  background-position: 50% 50%;
  position: fixed;
  z-index: 101;
  bottom: 0;
  top: 0;
  right: auto;
  left: 0;

  height: auto;
  padding: 1.45em 1em 0;
  width: 100%;
  max-width: 250px;

  // close transition
  transition: transform 0.175s $cubic-bezier;

  // mobile
  transform: translate3d(-100%, 0, 0);

  &.open {
    transform: translate3d(0, 0, 0);

    // open transition
    transition-duration: 0.225s;
  }

  @include breakpoint($breakpoint-small) {
    max-width: none;
    width: $side-nav-width;
    transform: translate3d(0, 0, 0);
    padding: 1.5em 1.5em 0;
  }

  @media print {
    display: none;
  }
}

.sidebarHeader {
  @include breakpoint($breakpoint-small, 'max') {
    display: flex;
    align-items: center;
    margin: -1.45em -1em 0.5em;
    height: 50px;
    padding: 0 1em;
  }
}

.appBrand {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  margin: 0 0 1.5em;
  user-select: none;

  @include breakpoint($breakpoint-small, 'max') {
    margin: 0;
  }
}

.logo {
  width: 2rem;
  height: auto;
  display: block;
  margin-right: 1em;
  @include breakpoint($breakpoint-small) {
    width: 2.5rem;
  }
}

.appName {
  font-weight: 600;
  position: relative;
  line-height: 1;
  font-size: 1.2em;

  color: #fff;
  @include breakpoint($breakpoint-small) {
    font-size: 1.4em;
  }
}

.siteInfo {
  margin: 0 0 1.5em;
  padding: 0 0 0.75em;
  border-bottom: 1px solid rgba(white, 0.2);

  @include breakpoint($breakpoint-small) {
    margin: 0 0 1.5em;
    padding: 0 0 0.75em;
  }
}

.siteName {
  font-weight: bold;
  color: #fff;
  font-size: 1.2em;
  line-height: 1.2;

  &.lessImportant {
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3.5em;
  cursor: pointer;
  color: #fff;

  &:hover .userName {
    color: #fff;
  }

  .avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0 0 1em;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }

  .userName {
    font-weight: bold;
  }
}

.sidebarContent {
  display: block;
}

.sidebarFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75em 1em;
  height: 60px;

  @include breakpoint($breakpoint-small) {
    padding: 1em 1.5em;
    height: 70px;
  }

  color: #fff;

  &:hover .userName {
    color: #fff;
  }

  .avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 1em 0 0;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

    border: 2px solid #fff;
    background-size: cover;
    background-position: 50% 50%;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
  }

  .links {
    display: block;
  }

  .userName {
    font-weight: bold;
  }
}

.link {
  color: #fff;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 600;
  text-decoration: underline;

  & + & {
    margin-left: 1em;
  }

  &:hover {
    text-decoration: none;
  }
}
