@import 'styles/variables.scss';
@import 'styles/animations.scss';
.card {
  display: inline-block;

  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  overflow: hidden;
  background: #fff;

  padding: 1em;
}

.block {
  display: block;
  width: 100%;
}

.cardHeader {
  padding: 0.25em 0.25em 0.5em;
  min-height: $card-header-height;
}

.cardTitle {
  color: $brand-grey;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 0.35em;
}

.cardSubTitle {
  margin: 0em;
  font-size: 1em;
  color: $grey-2;
}

@media print {
  .card {
    box-shadow: none;
    page-break-inside: avoid;
  }
}
