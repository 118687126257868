.flockler-chart {
  // # Typograpgy
  text,
  .bb-button,
  .bb-tooltip {
    font-family: $font-family-sans-serif;
  }

  // # Tooltip
  .bb-tooltip {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: $box-shadow-2;
    user-select: none;

    tr {
      border: none;

      td:not(:first-child) {
        border-left: 1px solid #fff;
      }
    }
    td {
      border: none;
      padding: 0.6em 0.75em;
      font-size: 1em;
      &.name > span {
        border-radius: 50%;
        position: relative;
      }
      &.value {
        font-weight: 600;
      }
    }
    th {
      background-color: $brand-primary;
      color: #fff;
      padding: 0.6em 0.75em;
    }
  }

  // # Axis
  .domain {
    stroke: $light-grey-3;
  }

  .bb-axis-y .domain {
    stroke-width: 0;
  }

  .tick {
    text {
      fill: $grey-1;
    }
    line {
      stroke: none;
    }
  }

  // # Area
  path.bb-shape.bb-area {
    fill: url(#data1Gradient) #fff !important;
    opacity: 0.2;
  }

  // # Line
  path.bb-shape.bb-line {
    stroke-width: 2;
    stroke: lighten($brand-primary, 10%) !important;
  }

  // # Point
  .bb-shape.bb-circle {
    fill: #fff !important;
    stroke: lighten($brand-primary, 10%) !important;
    stroke-width: 2;

    cursor: pointer;
  }

  // Legend
  .bb-legend-item {
    text {
      font-size: 1.15em;
    }
  }

  // Pie section hover
  .bb-target.bb-defocused {
    opacity: 0.6 !important;
  }
}
