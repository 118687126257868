@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/font.scss';
@import 'styles/icon-font.scss';
@import 'styles/charts.scss';

html,
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: $base-font-size-mobile;

  @include breakpoint($breakpoint-small) {
    font-size: $base-font-size;
  }
}

body {
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.333;
  color: $dark-grey;
  font-weight: 400;
  background: $ghost-white;
  -webkit-overflow-scrolling: touch;
}

a {
  color: $brand-primary;
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 0;
}

input,
textarea,
select,
button {
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  font-weight: bold;
  line-height: 1.2;
  color: $dark-grey;
}
h1 {
  font-size: 2.1em;
}

h2 {
  font-size: 1.7em;
}

h3 {
  font-size: 1.25em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
