@import "styles/variables.scss";

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
}

.loader.orphan {
  width: 100%;
}

.loader:not(.orphan) {
  margin-left: 10px;
}
