@import "styles/variables.scss";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 0.75s linear infinite;
  margin: 0 auto;

  border-radius: 50%;
  border: 3px solid $light-grey-2;
  border-top: 3px solid $brand-primary;

  &.theme--light {
    border: 3px solid rgba(white, 0.2);
    border-top: 3px solid white;
  }

  &.size--medium {
    width: 40px;
    height: 40px;
  }

  &.size--small {
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
}
