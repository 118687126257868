@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.postFooter {
  border-top: 1px solid #eee;
  margin: 0 1em;
  flex: 64px 0 0;
  display: flex;

  align-items: center;
  justify-content: space-around;

  @include breakpoint($breakpoint-small) {
    margin: 0 1.5em;
  }
}

.postKpi {
  flex: 1 0 0;
  color: rgba($brand-grey, 0.5);
  font-weight: 500;

  font-size: 0.9em;
  display: flex;
  flex-direction: column;
}

.postKpiInfo {
  flex: 1rem 0 0;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}

.postKpiNotAvailable {
  flex: 4 0 0;
  color: rgba($brand-grey, 0.5);
}

.kpiValue {
  margin-top: 0.1em;
  font-size: 1.2em;
  color: $brand-grey;
}
