@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.navigation {
  padding: 0em;
  margin: 0 -1em;
  color: #fff;
  font-weight: 500;

  @include breakpoint($breakpoint-small) {
    margin: 0 -1.5em;
  }
}

.separator {
  border-bottom: 1px solid rgba(white, 0.2);
  margin: 1.5em 1em;

  @include breakpoint($breakpoint-small) {
    padding: 1.25em 1.5em 0;
    margin: 0 1.5em 1.5em;
  }
}

.link {
  color: #fff;
  padding: 0.9em 1em;
  margin: 0 1em 0.5em;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.25em;

  @include breakpoint($breakpoint-small) {
    padding: 1em 1.25em;
    margin: 0 1.5em 0.5em;
  }

  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  display: flex;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  .linkText {
    font-size: 1.15em;
    font-weight: 600;
    display: flex;
    align-items: center;
    line-height: 1.15em;
  }

  .icon {
    font-size: 1.15em;
    margin: 0 0.7em 0 0;
    opacity: 0.9;
    float: left;
  }

  .imageIcon {
    opacity: 0.9;
    float: left;
    width: 1.5em;
    margin: -0.6em 1.05em -0.6em -0.25em;

    @include breakpoint($breakpoint-small) {
      margin-top: -0.4em;
      margin-bottom: -0.4em;
    }
  }

  .badge {
    position: absolute;
    min-width: 21px;
    font-size: 0.7em;
    font-weight: bold;
    color: #fff;
    padding: 2px;
    background: $brand-accent;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    // # Positioned right of the link
    right: 15px;
    top: 19px;

    // # Positioned on top of the icon
    z-index: 2;

    animation: scale-in 0.35s;
    transform-origin: 50% 50%;
  }

  &.active {
    color: #fff;
    .icon,
    .imageIcon {
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      border-radius: 3px 0 0 3px;
      background: white;

      @include breakpoint($breakpoint-small) {
        animation: bar-animation 0.4s ease;
      }
    }
  }
}

@keyframes bar-animation {
  0% {
    opacity: 0;
    transform: translate3d(-2px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
