// scss-lint:disable SelectorFormat

@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

$brand-grey: #5b6984;

@keyframes message-drop {
  0% {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  10% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  90% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0.5;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hide {
  0% {
    display: block;
  }
  99% {
    opacity: 1;
    display: block;
  }

  100% {
    opacity: 0;
    display: none;
    width: 0;
    height: 0;
  }
}

.introConversation {
  margin-top: 2em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  animation-name: hide;
  animation-fill-mode: forwards;

  .message {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
    font-size: 1.75em;
    line-height: 1.2;
    animation: message-drop;
    animation-fill-mode: forwards;
    margin-bottom: 1em;
    font-weight: 400;
    color: $brand-grey;

    @include breakpoint($breakpoint-small) {
      font-size: 2.25em;
    }
  }
}

.readyContent,
.delayedContent {
  opacity: 0;
  animation: fade-in 0.5s;
  animation-fill-mode: forwards;
  margin-top: 1em;

  @include breakpoint($breakpoint-small) {
    margin-top: 0.5em;
  }
}

.content {
  margin: 1em 0 0;

  h2 {
    margin: 1em 0 0.25em;
    padding: 0;
    color: $dark-grey;
    font-weight: 600;
  }
}

.postList {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -1em;
}

$max-cards-has-initial-animation: 6;
$max-cards-has-animation: 3;
$initial-delay: 0s;

.post {
  background: #fff;
  font-size: 1.25em;

  display: block;
  margin: 0.5em 1em;
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.2s;
  position: relative;

  opacity: 0;
  transform-origin: 50% 0;
  animation-name: subtle-drop;
  animation-timing-function: $cubic-bezier;
  animation-fill-mode: forwards;
  animation-duration: 0.45s;

  width: 100%;

  @include breakpoint($breakpoint-small) {
    font-size: 1em;
    margin: 1em;
    width: 330px;
  }

  .content & {
    // Animate first
    @for $i from 1 through $max-cards-has-animation {
      &:nth-child(#{$i}) {
        animation-delay: $i * (250ms);
      }
    }

    // show rest after
    &:nth-child(n + #{$max-cards-has-animation + 1}) {
      animation-delay: ($max-cards-has-animation + 1) * (250ms);
    }
  }

  .delayedContent & {
    // Animate first
    @for $i from 1 through $max-cards-has-initial-animation {
      &:nth-child(#{$i}) {
        animation-delay: $i * (250ms) + $initial-delay;
      }
    }

    // show rest after
    &:nth-child(n + #{$max-cards-has-initial-animation + 1}) {
      animation-delay: ($max-cards-has-initial-animation + 1) * (250ms) + $initial-delay;
    }
  }

  &:hover {
    box-shadow: $box-shadow-2;

    .postContent {
      color: $brand-primary;
    }
  }
}

.postIcon {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  font-size: 3.25em;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;
  color: $brand-success;
}

.postImage {
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-size: cover;
  background-position: 50% 50%;

  @include breakpoint(1200px) {
    height: 175px;
  }
}

.postContent {
  padding: 1em 1.25em;
  color: $grey-3;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.postTags {
  position: absolute;
  left: 1em;
  right: 1em;
  top: 1em;
  z-index: 2;

  opacity: 0;
  transform: translate3d(-0.75em, 0, 0);
  transition: all 0.2s;

  .post:hover & {
    @include breakpoint($breakpoint-small) {
      transform: none;
      opacity: 1;
    }
  }
}

.postTag {
  background: $brand-success;
  border-radius: 30px;
  height: 20px;
  line-height: 20px;
  font-size: 0.85em;
  color: #fff;
  padding: 0px 12px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0.5em 0.5em 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
}
