@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.noDataLabel {
  opacity: 0;

  animation: fade-in 0.3s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  margin: 0 auto;
  display: block;

  color: lighten($grey-1, 12.5%);
  font-weight: 500;
  font-size: 1.35em;
  line-height: 1.45;
  text-align: center;
}
