@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.secondaryContentWrap {
  margin: 4em auto 1em;
  text-align: center;
  display: flex;
  justify-content: center;
}

.postList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  max-width: 600px;
  margin: 1em auto;

  @include breakpoint($breakpoint-small) {
    margin-top: 2em;
  }

  animation: fade-in 0.2s;
}

.post {
  margin: 0.25em 0em 1.5em;

  background: #fff;
  border-radius: $card-border-radius;
  border: $card-border-width solid $card-border-color;
  box-shadow: $card-box-shadow;

  width: 100%;

  @media print {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    page-break-inside: avoid;
  }

  @include breakpoint($breakpoint-small, 'max') {
    overflow: hidden;
    margin-bottom: 0.75em;
  }

  @include breakpoint(1200px) {
    width: 600px;
  }

  display: flex;
  flex-direction: column;
  position: relative;
}

.postWithImage {
  @include breakpoint($breakpoint-small) {
    min-height: 260px;
    margin-top: 1.75em;
  }
}

.postTop {
  display: flex;
  flex-direction: column;

  @include breakpoint($breakpoint-small) {
    flex-direction: row;
  }
}

.postInfo {
  flex: 3 0 0;
  padding: 0 1em;

  @include breakpoint($breakpoint-small) {
    padding: 0 0 0 2em;
  }
}

.postInfoJustText {
  @include breakpoint($breakpoint-small) {
    padding-left: 1.5em;
  }
}

.orderNum {
  position: absolute;
  left: -55px;
  min-width: 40px;
  margin: 0.25em 0 0;
  text-align: right;
  font-size: 1.6em;
  color: $brand-grey;
  opacity: 0.4;
  font-weight: 600;
}

.postHeader {
  display: flex;

  justify-content: center;
  flex-direction: column;
  padding: 1em 0 0;

  @include breakpoint($breakpoint-small) {
    padding: 1.5em 0 0;
  }
}

.user {
  display: flex;
  align-items: center;

  position: relative;
}

.channelLabel {
  position: absolute;
  right: 0;
  top: 0.55rem;
  color: #cfd8dc;

  &:hover {
    color: #90a4ae;
  }

  @include breakpoint($breakpoint-small) {
    right: 1.5em;
  }

  svg {
    width: 2rem;
    height: 2rem;

    @include breakpoint($breakpoint-small) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.username {
  color: $brand-grey;
  font-weight: 600;
  margin-bottom: 0.1em;
  display: block;

  &:hover {
    color: $brand-primary;
  }

  .postWithImage & {
    max-width: 179px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.followerCount {
  color: $grey-2;
  font-size: 0.9em;
}

.postText {
  color: $dark-grey;
  padding: 1em 0;
  font-weight: 400;
  overflow: hidden;
  font-size: 1.15em;
  line-height: 1.5;

  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;

  @include breakpoint($breakpoint-small) {
    color: $brand-grey;
    font-size: 0.95em;
    line-height: 1.4;
    padding-right: 2em;
  }

  a {
    color: $dark-grey;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
