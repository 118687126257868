@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.richText {
  max-width: 800px;

  p {
    font-size: 1.2em;
    line-height: 1.6;
    color: $brand-grey;
    margin-bottom: 1.5rem;
  }

  .subHeading {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 2em;
  }

  .hilight {
    font-weight: 600;
  }
}
