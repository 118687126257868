@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.icon {
  color: $brand-primary;
  font-size: 2.6em;
  position: relative;
  user-select: none;
  display: block;

  text-shadow: 0 5px 2px rgba(0, 0, 0, 0.1);
}

.participants {
  top: 2px;
  font-size: 3.25em;
}

.entries {
  font-size: 3.4em;
  top: 8px;
  left: -12px;
}

.engagement {
  top: 2px;
}
