@import 'styles/variables.scss';
@import 'styles/animations.scss';

.filterInput {
  position: relative;
  z-index: 9;
}

.dropdown {
  width: 175px;
  min-height: 150px;
  background: #fff;
  border: 1px solid #eee;

  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  position: absolute;
  right: 0;
  margin-top: 0.5em;
  box-shadow: $box-shadow-1;

  animation: subtle-drop 0.2s;
}

.dropdownButton {
  background: #fff;
  border: none;
  font-weight: 400;
  font-size: 1em;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.1em;
  padding: 0.75em 1em;
  user-select: none;

  &:hover {
    cursor: pointer;
    // color: $brand-primary;
    background: $light-grey-1;
  }

  &.selected {
    color: $brand-primary;
  }
}

.buttonContent {
  flex: 1 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonIcon {
  margin-left: 0.5em;
}

.customDateRangePicker {
  position: absolute;
  margin-top: 0.5em;
  right: 0;
  z-index: 10;
}
