@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.list {
  &:not(.fullWidth) {
    max-width: 600px;
    margin: 0 auto;
  }
}

.card {
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  overflow: hidden;
  background: #fff;
  // box-shadow: $box-shadow;

  padding: 0.5em 1.3em;
  margin-bottom: 0.7em;
  min-height: 120px;
}

.cardTitle {
  color: $brand-grey;
  font-weight: bold;
  margin: 0.5em 1em 1em;
}

.cardSubTitle {
  margin: 0em 1em 1.5em;
  font-size: 0.9em;
  color: $grey-2;
}

.orderNum {
  font-weight: 600;
  color: $grey-1;
  font-size: 1em;
  min-width: 17px;
  letter-spacing: -0.03em;
  text-align: right;
  position: relative;
}

.tagName {
  display: block;
  color: $brand-primary;
  font-weight: 600;
  font-size: 1.25em;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}

.tagNamePlain {
  font-size: 1.15em;
  color: $brand-grey;
  font-weight: 600;

  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
}

.value {
  color: $brand-grey;
  font-size: 1.25em;
  text-align: right;
  display: block;
  width: 100%;
  font-weight: 400;
}

.noData {
  padding: 2em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showMore {
  text-align: center;
  margin: 1.5rem 0;
}
