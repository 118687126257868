@import 'styles/variables.scss';

$loading-spinner-stroke-offset: 187;
$loading-spinner-duration: 1.4s;

.spinner {
  animation: rotator $loading-spinner-duration linear infinite;
  transform: translate3d(0, 0, 0);

  & circle {
    stroke: $brand-primary;
    stroke-dasharray: $loading-spinner-stroke-offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $loading-spinner-duration ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $loading-spinner-stroke-offset;
  }

  50% {
    stroke-dashoffset: calc(#{$loading-spinner-stroke-offset} / 4);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $loading-spinner-stroke-offset;
    transform: rotate(450deg);
  }
}
