@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.actions {
  display: none;

  @include breakpoint($breakpoint-small) {
    display: flex;
  }
}

.fab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2em;
  border: 1px solid $light-grey-1;
  color: lighten($brand-primary, 10%);
  background: #fff;
  padding-top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: $box-shadow-2;
  }

  & + & {
    margin-left: 1em;
  }
}

.fabIcon {
  width: 1.1rem;
  height: 1.1rem;
  @include breakpoint($breakpoint-small) {
    width: 1.33rem;
    height: 1.33rem;
  }
}
