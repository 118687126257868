@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.hourHeatMapCard {
  margin-top: 1em 0;
}

.cardHeader {
  padding: 0 0.5em;
}

.cardTitle {
  color: $brand-grey;
  font-weight: 600;
  font-size: 1.2em;
  margin: 0.5em 0 0.35em;
}

.cardSubTitle {
  margin: 0 0 1.5em;
  font-size: 1em;
  color: $grey-2;
}

.hilight {
  // color: #fff;
  font-weight: 600;
  color: $dark-grey;
  display: inline-block;
  background: rgba($brand-primary, 0.1);
  padding: 2px 3px;
  margin: 0 2px 2px;
  border-radius: 2px;

  animation: enter 0.4s;
  opacity: 0;
  transform: scale(0.96);
  animation-fill-mode: forwards;

  @include breakpoint($breakpoint-small) {
    padding: 4px 6px 3px;
    margin: 0 3px;
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }
}

.cardContent {
  min-height: 100px;
  @include breakpoint($breakpoint-small) {
    padding: 0 0.5em;
  }

  animation: subtle-drop 0.2s;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 0;
}
