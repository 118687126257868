@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.notificationCenter {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 1rem;

  @include breakpoint($breakpoint-small) {
    left: $side-nav-width;
    right: 0;
    width: auto;
  }
}

.notification {
  position: relative;

  padding: 0.75em 2em;
  margin: 0 1.5em 1em;
  max-width: $breakpoint-medium;

  font-weight: 600;
  font-size: 1em;
  line-height: 1.5;
  // white-space: pre-line;

  @include breakpoint(#{$container-width + $side-nav-width}) {
    margin-left: auto;
    margin-right: auto;
    max-width: calc((#{$container-width} - 6em) / 1.2);

    font-size: 1em;
  }

  text-align: center;
  animation: subtle-drop 0.4s;
  background: #fff;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: $card-border-radius;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.075);

  &.info {
    background-color: $brand-primary;
    box-shadow: 0 10px 20px rgba($brand-primary, 0.2);
  }

  &.success {
    background-color: $brand-success;
    box-shadow: 0 10px 20px rgba($brand-success, 0.2);
  }

  &.error {
    background-color: $brand-accent;
    box-shadow: 0 10px 20px rgba($brand-accent, 0.2);
  }
}

.content {
  max-width: $breakpoint-medium;
  margin: 0 auto;
}

.close {
  width: 60px;
  height: 60px;
  display: inline-block;
  position: absolute;
  right: 0;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 2em;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
