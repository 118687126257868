@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.pillTabs {
  display: flex;
  margin: 0 0 1.5em;
  justify-content: center;
}

.pillTab {
  background: transparent;
  font-size: 0.9em;
  font-weight: 600;
  color: $brand-grey;
  border: none;
  padding: 0.4em 1em;
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.075);
  text-align: center;
  user-select: none;

  & + & {
    margin-left: 0.5em;
  }

  @include breakpoint($breakpoint-small) {
    font-size: 1em;
    & + & {
      margin-left: 1.2em;
    }
  }

  transition: all 0.1s;
  &:hover {
    background: rgba(255, 255, 255, 1);
    box-shadow: $box-shadow;
    border-color: transparent;
  }

  &:active {
    box-shadow: none;
  }

  &.selected {
    color: #fff;

    text-decoration: none;
    border-color: transparent;
    box-shadow: $box-shadow;
    transform: none;
    overflow: hidden;
    background: $brand-accent;
  }
}
