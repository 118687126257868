@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleY-in {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes enter {
  0% {
    transform: scale(0.96);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes subtle-drop {
  0% {
    opacity: 0;
    transform: translate3d(0, -5px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes subtle-pop {
  0% {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes subtle-come-from-left {
  0% {
    opacity: 0;
    transform: translate3d(-5px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes subtle-scale-in {
  0% {
    opacity: 0;
    transform: scaleX(0.975);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
