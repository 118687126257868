@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.linkTabs {
  display: flex;
  margin: 0.5em 0 1.5em;
  // justify-content: center;

  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.linkTab {
  background: transparent;
  font-size: 1.1em;
  font-weight: bold;
  color: $grey-1;
  border: none;
  padding: 0.4em 1px;
  margin-bottom: -1px;
  cursor: pointer;
  text-align: center;
  position: relative;
  display: inline-block;
  user-select: none;

  & + & {
    margin-left: 1.5em;
  }

  @include breakpoint($breakpoint-medium) {
    font-size: 1.25em;
    & + & {
      margin-left: 2em;
    }
  }

  transition: all 0.1s;
  &:hover {
    color: $brand-grey;
  }

  &:active {
    color: $dark-grey;
  }

  &.selected {
    color: $dark-grey;
    // box-shadow: inset 0 -2px 0 $brand-accent;
    text-decoration: none;
    border-color: transparent;
    transform: none;

    &:after {
      content: '';
      display: block;
      height: 2px;
      background: $brand-primary;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;

      transform-origin: 0 50%;
      animation: scale-in 0.2s;
    }
  }
}
