@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  background-image: url('../../assets/svgs/brand-bg.svg');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.card {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.05);

  padding: 2rem 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint($breakpoint-medium, 'max') {
    max-width: 80%;
  }
}

.icon {
  animation: subtle-drop 0.25s;
  line-height: 1.2;
  color: $color-blue;
  width: 8em;
  display: block;
  margin-bottom: 2em;
}

.content {
  opacity: 0;
  transform: translateY(-1rem);
  animation: subtle-drop 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
  max-width: 40rem;

  text-align: center;
}

.title {
  font-size: 1.65rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}

.actions {
  opacity: 0;
  transform: translateY(-1rem);
  animation: subtle-drop 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}
