@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.wrap {
  padding: 1em;
  max-width: $container-width;
  margin: 0 auto;

  // Force scrollbar "hack"
  // to prevent scrollbar show&hide on loading phases
  min-height: calc(100vh + 1px);

  @include breakpoint($breakpoint-small, 'max') {
    padding-top: $header-height-mobile;
    padding-bottom: 5rem;
  }

  @include breakpoint(1200px) {
    padding: 1.5em 3.5em;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // Keeps page header consistent when action buttons are rendered
  min-height: 3.5em;

  @include breakpoint($breakpoint-small, 'max') {
    margin-bottom: 0.5em;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(7px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 0;
    height: $header-height-mobile;
    z-index: 99;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
  }
}

.actions {
  display: none;

  @include breakpoint($breakpoint-small) {
    display: flex;
  }
}

.fab {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2em;
  border: 1px solid $light-grey-1;
  color: $brand-primary;
  background: #fff;
  padding-top: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s;
  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: $box-shadow-2;
  }

  & + & {
    margin-left: 1em;
  }
}

.fabIcon {
  width: 1.1rem;
  height: 1.1rem;
  @include breakpoint($breakpoint-small) {
    width: 1.33rem;
    height: 1.33rem;
  }
}

.pageTitle {
  flex: 75% 0 0;

  @include breakpoint($breakpoint-small, 'max') {
    flex: 100% 0 0;
  }
}

.content {
  display: block;
}

.mobileNav {
  border: none;
  background: transparent;
  width: $header-height-mobile + 10px;
  height: $header-height-mobile;
  margin-right: 5px;

  // padding: 16px 20px 0;
  position: relative;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  .mobileNavIcon {
    width: 17px;
    height: 14px;
    fill: url(#navIconGradient) $brand-primary;
    color: $brand-primary;
    position: relative;
    &:active {
      opacity: 0.7;
    }
  }

  .mobileNavIconLine {
    width: 19px;
    height: 2px;
    background: $brand-primary;
    display: block;
    position: relative;
    margin: 4px 0;
    transform: translate3d(0, 0, 0) scale(1);

    &:nth-child(3) {
      width: 13px;
    }
  }

  &:active .mobileNavIconLine {
    opacity: 0.6;
  }

  @include breakpoint($breakpoint-small) {
    display: none;
  }
}

@media print {
  .wrap {
    padding: 0;
    max-width: none;
  }

  .header {
    display: none;
  }
}
