@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.pageTitle {
  padding: 0;
  margin: 0;

  @include breakpoint($breakpoint-medium, 'max') {
    font-size: 1.5rem;
  }

  @include breakpoint($breakpoint-small, 'max') {
    font-size: 1.4em;
    line-height: 1;
    padding-top: 2px;
  }

  .pageSubTitle {
    margin-left: 0.35em;
  }

  .pageSubTitleFilterName {
    color: rgba($brand-primary, 0.9);
    text-transform: capitalize;
  }
}
