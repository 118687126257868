@import 'styles/variables.scss';

@mixin button-theme($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 3%);
    border-color: darken($border, 3%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 3%);
    border-color: darken($border, 3%);
  }
  &:active,
  &.active {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 10%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 10%);
      border-color: darken($border, 10%);
    }
  }
  &:active,
  &.active {
    background-image: none;
  }
  &.disabled,
  &[disabled] {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }
}

%action-button {
  font-weight: 600;
  text-transform: uppercase;
}

.button {
  background: transparent;
  border: 2px solid $brand-primary;
  font-family: $font-family-sans-serif;

  font-weight: bold;
  font-size: 1em;
  text-align: center;
  text-transform: none;
  position: relative;

  padding: 9px 15px;
  margin: 0;

  display: inline-block;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: $button-border-radius;
  background: #fff;

  outline: none;
  color: $brand-primary;
  transition: all 0.2s;
  user-select: none;

  &.theme--default {
    // default is DEFAULT
  }

  &.theme--primary {
    @include button-theme(white, $brand-primary, $brand-primary);
  }
  &.theme--accent {
    @include button-theme(white, $brand-accent, $brand-accent);
  }
  &.theme--success {
    @include button-theme(white, $brand-success, $brand-success);
  }

  &.theme--link {
    @include button-theme($brand-primary, transparent, transparent);
    padding: 0;
    min-width: 0;
    font-weight: 600;
    box-shadow: none;
    &:hover {
      text-decoration: underline;
    }
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  // # Size
  &.size--small {
    padding: 0.5em 1em;
    font-size: 0.85em;
    min-width: 0;
  }

  &.size--medium {
    font-size: 1em;
  }

  &.size--large {
    font-size: 1.2em;
  }

  // Side by side buttons should have margin
  & + &:not(.style--inline) {
    margin-left: 15px;
  }

  &.style--block {
    display: block;
    width: 100%;
  }

  // # interactive styles
  &:hover,
  &:active {
    text-decoration: none;
    cursor: pointer;
  }
  &:focus {
    box-shadow: $box-shadow;
  }

  &.disabled,
  &[disabled] {
    opacity: 0.7;
  }
}

@media (min-width: $breakpoint-medium) {
  .button {
    min-width: 150px;
  }
}
