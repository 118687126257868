@import 'styles/variables.scss';
@import 'styles/animations.scss';

.wrap {
  display: block;
  overflow-x: auto;
  margin: 0;
  position: relative;
  min-height: 120px;
}

.table {
  width: 100%;
  display: table;

  display: flex;
  flex-direction: column;

  font-size: 0.9em;
}

.loadingLayer {
  padding: 25px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  top: 40px;
  min-height: 60px;
  border-radius: 4px;

  background: rgba($ghost-white, 0.8);
}

@media (min-width: $breakpoint-small) {
  .wrap {
    margin: 0 0;
  }

  .table {
    font-size: 1em;
  }
}
