@import 'styles/variables.scss';
@import 'styles/animations.scss';

$data-color: $brand-primary;
$label-color: $grey-1;

$no-data-color: #f2f2f2;

.heatmap {
  margin-left: -1em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  animation: fade-in 0.15s $cubic-bezier;
  justify-content: center;

  @media print {
    -webkit-print-color-adjust: exact;
  }
}

.heatmapData {
  display: flex;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex-wrap: nowrap;
}

.rowLabel {
  color: $label-color;
  font-size: 0.9em;
  padding: 5px 0 2px;
  display: block;
  transition: all 0.1s;
  min-height: 22px;
}

.rowData {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: calc((100vw - 90px) / 8);
  transition: all 0.1s;
}

.value {
  display: none;
  font-size: 0.65em;
  .level--0 & {
    display: none;
  }
}

@mixin cell-size($width, $height, $fontSize, $margin) {
  width: $width;
  height: $height;
  min-width: $width;
  min-height: $height;

  margin: $margin;

  font-size: $fontSize;
}

.cell {
  background: $no-data-color;
  color: #fff;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  border: 1px solid #fff;
  border-width: 0 0 1px 1px;

  border-radius: 2px;
  @include cell-size(calc((100vw - 90px) / 8), 18px, 1em, 1px 1px 0 0);

  transition: 0.1s ease;
  transform-origin: 50% 50%;

  &:hover:not(.level--0):not(.labelCell) {
    box-shadow: 0 2px 5px rgba(#000, 0.25);
  }
}

// CSS Modules does not always recognize
// dynamically generated classNames
.level--empty,
.level--0,
.level--1,
.level--2,
.level--3,
.level--4,
.level--5,
.level--6,
.level--7,
.level--8,
.level--9,
.level--10 {
  color: #fff;
}

// # Scale coloring
// darken each level by 6%
@for $level from 1 through 10 {
  .level--#{$level} {
    background: lighten($data-color, (10 - $level) * 5.5%);
  }
}

.level--0 {
  background: $no-data-color;
}

.level--nodata,
.level--empty {
  background: $no-data-color;
}

.labelCell {
  @extend .cell;

  background: transparent;
  color: $label-color;
  text-align: center;

  .text {
    font-size: 0.75em;
    line-height: 1;
    margin-top: 0em;
    font-weight: 500;
  }
}

@media (min-width: $breakpoint-small) {
  .heatmap {
    margin: 0;
  }

  .rowData {
    width: auto;
  }

  .cell {
    border: none;
    @include cell-size(24px, 24px, 0.85em, 2px);
  }
}

@media (min-width: 1024px), print {
  .heatmap {
    display: block;
    margin-left: 0;
  }

  .heatmapData {
    display: block;
  }

  .rowLabel {
    min-width: 45px;
    display: flex;
    align-items: center;
    padding: 0 15px 0 0;
    text-align: left;
    font-size: 0.85em;
    min-height: 30px;
  }

  .row {
    flex-direction: row;
    min-height: 30px;
  }

  .rowData {
    flex-direction: row;
    width: auto;
  }

  .cell {
    border: none;
    @include cell-size(24px, 24px, 0.85em, 2px);
  }

  .value {
    display: none;
    font-size: 0.8em;
    font-weight: 600;
    .level--0 & {
      display: none;
    }
  }

  .level--0 {
    background: $no-data-color;
  }

  .level--nodata {
    // background: $light-grey-3;
  }
}

@media (min-width: 1230px), print {
  .cell {
    @include cell-size(28px, 28px, 0.85em, 3px);
  }
}

@media (min-width: 1420px) {
  .row {
    min-height: 41px;
  }

  .rowLabel {
    font-size: 0.9em;
  }

  .cell {
    @include cell-size(35px, 35px, 0.9em, 3px);
  }
}

@media (min-width: 1600px) {
  .rowLabel {
    font-size: 1em;
  }

  .cell {
    @include cell-size(39px, 39px, 1.1em, 4px);
  }
}
