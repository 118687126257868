@import 'styles/variables.scss';

@keyframes fl-loading-animation {
  0% {
    background: $grey-1;
    transform: scale(0.5);
  }
  50% {
    background: white;
    transform: scale(1);
  }
  100% {
    background: $grey-1;
    transform: scale(0.5);
  }
}

.loadingIndicator {
  height: 40px;
  margin-bottom: 10px;
  position: relative;

  & div {
    animation: fl-loading-animation 2s infinite;
    animation-fill-mode: both;
    background: $grey-1;
    border-radius: 100%;
    height: 10px;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 10px;
  }

  & div:nth-child(1n) {
    animation-delay: 0s;
    left: 38%;
  }

  & div:nth-child(2n) {
    animation-delay: 0.2s;
    left: 48%;
  }

  & div:nth-child(3n) {
    animation-delay: 0.4s;
    left: 58%;
  }

  &.infinite {
    height: 20px;
    width: 100px;
  }
}
