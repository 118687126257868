.wrap {
  margin: 1rem 0 2rem;
}

.viewLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}
