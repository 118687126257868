@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.row {
  margin-bottom: 0;

  display: table-row;
  display: flex;
  flex: 1;
  font-size: 1em;

  &:last-child .col {
    border: none;
  }

  &.cardRow {
    background: #fff;
    border-radius: $card-border-radius;
    border: 1px solid $card-border-color;
    align-items: center;
    margin: 0 0 0.5em;
    padding: 0 0.5em;
    overflow: hidden;
    position: relative;

    @include breakpoint($breakpoint-small) {
      padding: 0 1em;
    }
  }

  &.animated {
    $max-rows-has-animation: 30;
    opacity: 0;

    transform-origin: 0 0;
    animation-name: subtle-scale-in;
    animation-timing-function: $cubic-bezier;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;

    @for $i from 1 through $max-rows-has-animation {
      &:nth-child(#{$i}) {
        animation-delay: $i * (70ms) - ($i * 4);
      }
    }

    &:nth-child(n + #{$max-rows-has-animation + 1}) {
      animation-delay: ($max-rows-has-animation + 1) * (70ms) - ($max-rows-has-animation * 4);
    }
  }
}

.col {
  height: 50px;
  padding: 0 0.5em;

  display: table-cell;
  vertical-align: middle;
  border-bottom: 0px solid #eee;

  display: flex;
  align-items: center;

  flex: 1 0 0;
  // width: 100%;

  white-space: nowrap;
  color: $dark-grey;

  &.alignRight {
    justify-content: flex-end;
    text-align: right;
  }
}

@media (min-width: $breakpoint-small) {
  .row {
    width: 100%;
  }

  .col {
    height: 4.6em;
    padding: 0 0.5em 0 0;
    vertical-align: middle;
    &.less-important {
      display: table-cell;
    }
    &.multi-row {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

@media print {
  .row.cardRow {
    animation: none;
    opacity: 1;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border-width: 0 0 1px 0;
  }
}
