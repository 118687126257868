@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.noData,
.loadingFailed {
  height: 302px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plainPost {
  display: block;
  margin: 0 1em 0;

  background: #fff;
  width: 210px;
  font-size: 0.9em;
}

.postHeader {
  min-height: 3.5em;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .username {
    color: $brand-grey;
    font-weight: 600;

    &:hover {
      color: $brand-primary;
    }
  }

  .followerCount {
    color: $grey-2;
    font-size: 0.8em;
  }
}

.orderNo {
  color: $brand-primary;
  font-weight: bold;
  font-size: 1.2em;
  display: none;
}

.postFooter {
  height: 2em;
  padding: 0.75em 0 0;
  color: $grey-2;
  display: flex;
  align-items: center;
}

.likeCount {
  margin: 0 0 0 6px;
  font-size: 0.8em;
  position: relative;
  top: 1px;
  &:not(:last-child) {
    margin-right: 1.5em;
  }
}

.postContent {
  display: block;
  height: 210px;
  background-color: $ghost-white;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.postImageFallback {
  background: transparent;

  .channel--facebook & {
    color: lighten($color-facebook, 20%);
  }

  .channel--instagram & {
    color: lighten($color-instagram, 25%);
  }

  .channel--twitter & {
    color: lighten($color-twitter, 20%);
  }

  .channel--linkedin & {
    color: lighten($color-linkedin, 20%);
  }

  @include breakpoint($breakpoint-small) {
    background: $ghost-white;
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.postImageFallbackLogo {
  // center
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -23px 0 0 -23px;

  display: inline-block;
  width: 46px;
  height: 46px;
}

.postText {
  background: lighten($brand-primary, 17%);
  background: linear-gradient(135deg, lighten($brand-primary, 21%), lighten($brand-primary, 15%));
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  color: #fff;
  font-weight: 500;
  line-height: 1.35;
  overflow: hidden;

  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;

  &.small {
    font-size: 1rem;
  }

  &.large {
    font-size: 1.5rem;
  }

  &.medium {
    font-size: 1.25rem;
  }
}

.postImage {
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  z-index: 2;
}

.scrollWrap {
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.itemsScrollWrap {
  margin: 0;
  padding: 0.5em 0 0.75em;
}

.arrowWrap {
  position: static;
}

.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 9;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;

  font-size: 2rem;
  background: rgba(#fff, 0.85);
  color: $dark-grey;
  opacity: 0;

  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition-delay: 0.5s;

  &.arrowLeft {
    transform: translate3d(-100%, 0, 0);
  }

  &.arrowRight {
    transform: translate3d(100%, 0, 0);
  }

  .itemsScrollWrap:hover & {
    transition-delay: 0s;
    opacity: 0.7;
    transform: translate3d(0, 0, 0) !important;
    &:hover {
      opacity: 1;
      .arrowIcon {
        transform: scale(1.1);
      }
    }
  }

  &[disabled] {
    opacity: 0 !important;
  }
}
.arrowIcon {
  transform-origin: 50% 50%;
  transition: transform 0.2s;
  will-change: transform;
}

.arrowLeft {
  left: 0;
}

.arrowRight {
  left: auto;
  right: 0;
}

.arrowDisabled {
  display: none;
}

.readMorePost {
  width: 170px;
  margin-right: 0;
  float: right;
}

.readMoreContent {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  color: $brand-primary;
  font-weight: 600;
  font-size: 1.5em;

  &:hover {
    .readMoreLink {
      text-decoration: underline;
    }
  }
}

.moreArrowIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.mobileContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -webkit-oveflow-scrolling: touch;
  overflow-x: scroll;
  padding: 1em;

  .plainPost {
    flex: 210px 0 0;
    min-width: 210px;
  }
}

@media print {
  .mobileContainer {
    overflow-x: hidden;
  }
}

.print {
  display: none;
}

@media print {
  .print {
    display: inline-block;
  }

  .postImage:not(.print) {
    display: none;
  }
}
