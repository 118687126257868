@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.channelInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 15px;

  font-weight: 600;
  font-size: 0.9em;
  text-transform: capitalize;
  border-radius: 25px;
  min-width: 92px; // Instagram will be same width as FB and Twitter

  position: relative;

  .bg {
    background: transparent;
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 1;
    border-radius: 25px;
  }

  .text {
    position: relative;
    z-index: 2;
  }

  &.channel--instagram {
    color: $color-instagram;
    @include instagram-background(0.1);
    // background: rgba($color-instagram, 0.15);
  }

  &.channel--twitter {
    color: $color-twitter;
    background: rgba($color-twitter, 0.15);
  }

  &.channel--facebook {
    color: $color-facebook;
    background: rgba($color-facebook, 0.15);
  }

  &.channel--linkedin {
    color: $color-linkedin;
    background: rgba($color-linkedin, 0.15);
  }
}
