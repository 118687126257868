@import 'styles/variables.scss';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url('../../assets/svgs/brand-bg.svg');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-color: #f8f8fb;
  background-size: cover;

  max-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh;
}

.card {
  align-self: flex-start;
  max-width: 660px;
  width: 90vw;
  border-radius: 0.5rem;
  background: white;
  text-align: center;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.05);
}

.title {
  font-size: 1.75rem;
  margin: 0;
}

.content {
  margin-top: 1rem;
}
