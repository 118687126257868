// scss-lint:disable SelectorFormat
@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

$wide-breakpoint: $side-nav-width + $container-width;

.liftCard.bigCard {
  padding-bottom: 0;
  width: 445px;
}

.contentTitle {
  font-size: 1.5em;
  margin-bottom: 0;
  padding-bottom: 0;
}

.cols {
  margin: 0 0 2em;

  .colWide {
    margin: 0 0 1em;
    width: 100%;
  }

  .colNarrow {
    margin: 0;
    width: 100%;
  }

  .col {
    width: 100%;
  }

  @media (min-width: 1200px), print {
    display: flex;

    .colWide {
      flex: 2.21 0 0;
      margin: 0;
      width: auto;
    }

    .colNarrow {
      flex: 1 0 0;
      margin: 0 0 0 1em;
      width: auto;
    }

    .col {
      flex: 1 0 0;
      width: auto;
    }

    .col + .col {
      margin: 0 0 0 1em;
    }
  }

  @include breakpoint($wide-breakpoint) {
    .colNarrow {
      margin: 0 0 0 1.5em;
    }

    .col + .col {
      margin: 0 0 0 1.5em;
    }
  }
}
