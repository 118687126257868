@import 'styles/variables.scss';
@import 'styles/mixins.scss';

$avatar-border-radius: 50%;
$large-avatar-border-radius: 40px;

.link {
  display: inline-block;
  position: relative;
  background-color: $light-grey-1;
  border-radius: $avatar-border-radius;
  width: 40px;
  height: 40px;
  // overflow: hidden;
  margin-top: 1px;

  @include breakpoint($breakpoint-small) {
    margin-top: 2px;
    margin-right: 1em;
  }

  &.sizeLg {
    margin: 0;
    border-radius: $large-avatar-border-radius;
    width: 100px;
    height: 100px;
  }
}

.avatar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;

  width: 40px;
  height: 40px;
  display: inline-block;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: $avatar-border-radius;

  .sizeLg & {
    border-radius: $large-avatar-border-radius;
    width: 100px;
    height: 100px;
  }
}

.textAvatar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-weight: 600;
  border-radius: $avatar-border-radius;

  padding-bottom: 1px;
  color: rgba(#fff, 0.9);

  .sizeLg & {
    border-radius: $large-avatar-border-radius;
    width: 100px;
    height: 100px;
    font-size: 2rem;
  }

  &.channel--instagram {
    background-color: lighten($color-instagram, 20%);
    @include instagram-background(0.66);
  }

  &.channel--twitter {
    color: #fff;
    background: rgba($color-twitter, 0.66);
  }

  &.channel--facebook {
    color: #fff;
    background: rgba($color-facebook, 0.66);
  }

  &.channel--linkedin {
    color: #fff;
    background: rgba($color-linkedin, 0.66);
  }
}

.badge {
  position: absolute;
  right: -0.3rem;
  top: -0.3rem;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.075);
  font-size: 1em;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;

  .socialIcon {
    width: 1.2em;
    height: 1.2em;
    &.icon--instagram {
      color: $color-instagram;
    }

    &.icon--twitter {
      color: $color-twitter;
    }

    &.icon--facebook {
      color: $color-facebook;
    }

    &.icon--linkedin {
      color: $color-linkedin;
    }
  }
}

.print {
  display: none;
}

@media print {
  .print {
    display: inline-block;
  }
}
