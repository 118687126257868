@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.infoIcon {
  color: rgba($brand-grey, 0.4);
  cursor: help;

  &:hover {
    color: rgba($brand-grey, 0.8);
  }
}

.tooltip {
}

.tooltipTitle {
  font-weight: bold;
  padding: 0 0.125rem;
  margin-bottom: 0.5rem;
  font-size: 1.15rem;
  color: #fff;
  border-bottom: 0px solid rgba(255, 255, 255, 0.1);
}

.values {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.125rem;
}

.engagment {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;

  & + & {
    margin-left: 1.5rem;
  }

  .label {
    font-weight: 500;
    margin-right: 0.25rem;
  }
}
