@font-face {
  font-family: 'Avenir-Next';
  font-style: normal;
  font-weight: 500;
  src: local('Avenir Next Medium'), local('AvenirNext-Medium'),
    url('../assets/fonts/avenir-medium.woff2') format('woff2'),
    url('../assets/fonts/avenir-medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-style: italic;
  font-weight: 500;
  src: local('Avenir Next Medium Italic'), local('AvenirNext-MediumItalic'),
    url('../assets/fonts/avenir-medium-italic.woff2') format('woff2'),
    url('../assets/fonts/avenir-medium-italic.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-style: normal;
  font-weight: 600;
  src: local('Avenir Next Demi Bold'), local('AvenirNext-DemiBold'),
    url('../assets/fonts/avenir-demi-bold.woff2') format('woff2'),
    url('../assets/fonts/avenir-demi-bold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-style: italic;
  font-weight: 600;
  src: local('Avenir Next Demi Bold Italic'), local('AvenirNext-DemiBoldItalic'),
    url('../assets/fonts/avenir-demi-italic.woff2') format('woff2'),
    url('../assets/fonts/avenir-demi-italic.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-style: normal;
  font-weight: 700;
  src: local('Avenir Next Bold'), local('AvenirNext-Bold'),
    url('../assets/fonts/avenir-bold.woff2') format('woff2'),
    url('../assets/fonts/avenir-bold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-style: italic;
  font-weight: 400;
  src: local('Avenir Next Italic'), local('AvenirNext-Italic'),
    url('../assets/fonts/avenir-italic.woff2') format('woff2'),
    url('../assets/fonts/avenir-italic.woff') format('woff');
}

@font-face {
  font-family: 'Avenir-Next';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir Next Regular'), local('AvenirNext-Regular'),
    url('../assets/fonts/avenir-regular.woff2') format('woff2'),
    url('../assets/fonts/avenir-regular.woff') format('woff');
}
