@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

$card-with-icons-breakpoint: 1100px;
$desktop-card-breakpoint: $side-nav-width + 44rem;
$wide-card-breakpoint: $side-nav-width + $container-width;

.kpiList {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -0.35em;

  @include breakpoint($desktop-card-breakpoint) {
    margin: 0 -0.5em;
  }
  @include breakpoint($wide-card-breakpoint) {
    margin: 0 -0.75em;
  }
}

.kpiCard {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  border-radius: $card-border-radius;
  overflow: hidden;
  background: #fff;
  border: $card-border-width solid $card-border-color;
  box-shadow: $card-box-shadow;

  margin: 0 0.5em 1em;
  height: 95px;
  padding: 1.25em 0.15em;
  font-size: 0.7em;

  @media print {
    height: 105px;
  }

  @include breakpoint($card-with-icons-breakpoint) {
    flex-direction: row;
  }

  @include breakpoint($desktop-card-breakpoint) {
    font-size: 1em;
    flex: 1 0 0;
    height: 130px;
    padding: 1.4em;
    margin: 0 0.5em 1em;
  }

  @include breakpoint($wide-card-breakpoint) {
    height: 145px;
    margin: 0 0.75em 1.4em;
    padding: 1.75em;
  }
}

.iconWrap {
  display: none;

  font-size: 1.1em;
  width: 70px;
  height: 70px;
  min-width: 70px;
  border-radius: 20px;
  // background: $ghost-white;
  background: rgba($brand-primary, 0.05);

  margin-right: 2.5em;
  position: relative;
  overflow: hidden;

  transition: all 0.1s;
  &:hover {
    background: rgba($brand-primary, 0.085);
  }

  @include breakpoint($card-with-icons-breakpoint) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: help;
  }

  .customIcon {
    display: block;
    opacity: 0;
    transform: translate3d(0, 5px, 0);

    animation-name: subtle-pop;
    animation-timing-function: $cubic-bezier;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    animation-delay: 0.3s;
  }
}

.icon {
  color: $brand-primary;
  font-size: 2.05em;
  position: relative;
  z-index: 2;
}

.emojiIcon {
  position: relative;
  top: 0.1em;
  font-size: 1.9em;
}

.kpiCardContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;

  @include breakpoint($card-with-icons-breakpoint) {
    align-items: flex-start;
  }
}

.kpiValueLoader {
  position: relative;
  top: -0.2rem;

  @include breakpoint($card-with-icons-breakpoint) {
    top: 0.55rem;
    left: -35px;
  }
}

.kpiValue {
  font-size: 3em;
  font-weight: 600;
  line-height: 1;
  color: $brand-grey;
  margin: 0.4em 0 0;
  letter-spacing: -1px;

  @include breakpoint($breakpoint-small) {
    margin: 0.1em 0 0;
  }

  @include breakpoint($desktop-card-breakpoint) {
    margin: 0.1em 0 0;
    font-size: 3em;
    letter-spacing: 0px;
  }

  @include breakpoint($wide-card-breakpoint) {
    margin: 0;
    font-size: 3.85em;
  }

  @media print {
    font-size: 4em;
  }
}

.kpiLabel {
  color: $card-title-color;
  font-weight: 500;
  margin: 0.2em 0 0.25em;
  text-align: center;
  font-size: 1.1em;

  @include breakpoint($card-with-icons-breakpoint) {
    text-align: left;
  }

  @include breakpoint($desktop-card-breakpoint) {
    font-size: 1em;
    margin: 0.3em 0 0.2em 0.25em;
  }

  @include breakpoint($wide-card-breakpoint) {
    margin: -0.1em 0 0.55em 0.2em;
  }
}

.kpiProgress {
  color: $brand-success;
  font-weight: 600;
  animation: fade-in 0.2s;
  cursor: help;

  margin-top: 0.7em;

  @include breakpoint($breakpoint-small, 'max') {
    margin-top: 0.5em;
  }

  .percentage {
    display: inline-block;
    margin-left: 0.25rem;

    @media print {
      display: inline;
    }

    @include breakpoint($breakpoint-small) {
      display: inline;
      margin-left: 0;
    }
  }

  @include breakpoint($desktop-card-breakpoint) {
    margin-top: 0.2em;
  }

  &.positive,
  &.negative {
    &:before {
      content: '';
      display: inline-block;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: relative;
      margin-right: 0.1em;
      margin-left: 0.3em; // keep the value centered
      transform: scale(0.75);

      @include breakpoint($desktop-card-breakpoint) {
        top: -2px;
        transform: scale(1);
        margin-right: 0.3em;
        // margin-left: -0.3em;
      }
    }
  }

  &.positive:before {
    border-bottom: 5px solid $brand-success;
  }

  &.negative {
    color: $brand-accent;

    &:before {
      border-top: 5px solid $brand-accent;
    }
  }
}
