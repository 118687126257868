@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.rowHeader {
  margin-bottom: 0;
  display: table-row;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  font-weight: 500;
  font-size: 1em;
  background: transparent;

  &.cardRow {
    padding: 0 0.5em;
    @include breakpoint($breakpoint-small) {
      padding: 0 1em;
    }
  }
}

.col {
  flex: 1;
  min-height: 26px;
  display: block;
  flex: 1 0 0;
  padding: 0 0.5em;

  &:last-child {
    padding-right: 0;
  }

  @include breakpoint($breakpoint-small) {
    min-height: 40px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    text-align: left;
    text-transform: uppercase;

    // un-buttonize
    border: none;
    background: transparent;
    font-size: 0.75em;
    color: lighten($grey-1, 10%);
    font-weight: 600;
    min-height: 26px;

    @include breakpoint($breakpoint-small) {
      letter-spacing: 0.75px;
      min-height: 35px;
    }

    &.alignRight {
      text-align: right;
      justify-content: flex-end;
    }
  }

  .sortIcon {
    display: inline-block;
    float: right;
    width: 0.7em;
    height: 0.85em;
    margin-left: 0.5em;
    position: relative;

    &:after,
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      border-left: 0.36em solid transparent;
      border-right: 0.36em solid transparent;
    }
    &:before {
      top: 0;
      border-bottom: 0.36em solid $light-grey-2;
    }
    &:after {
      bottom: 0;
      border-top: 0.36em solid $light-grey-2;
    }
  }

  .sortable {
    cursor: pointer;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      .sortIcon {
        &:after {
          border-top-color: $brand-primary;
        }
      }

      &.descending {
        .sortIcon {
          &:after {
            border-top-color: $light-grey-2;
          }
          &:before {
            border-bottom-color: $brand-primary;
          }
        }
      }
    }
  }
}

.infoIcon {
  float: right;
  display: block;
  margin-left: 0.3rem;
  font-size: 0.95rem;
  position: relative;
  top: -1px;
}

.tooltipLabel {
  cursor: help;
  &:hover {
    color: darken($grey-1, 10%);
  }
}

.tooltipContent {
  @include breakpoint($breakpoint-small, 'max') {
    max-width: 85vw;
    display: block;
    white-space: normal;
    pre {
      white-space: normal;
    }
  }
}

@media (min-width: $breakpoint-small) {
  .col {
    height: 2.6em;
    padding: 0 0.5em 0 0;
  }
}
