@mixin instagram-background($bg-opacity: 1) {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      rgba(#fec564, $bg-opacity),
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, rgba(#fec564, $bg-opacity), transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, rgba(#5258cf, $bg-opacity), transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      rgba(#5258cf, $bg-opacity),
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, rgba(#893dc2, $bg-opacity), transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      rgba(#893dc2, $bg-opacity),
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, rgba(#d9317a, $bg-opacity), transparent),
    linear-gradient(
      rgba(#6559ca, $bg-opacity),
      rgba(#bc318f, $bg-opacity) 30%,
      rgba(#e33f5f, $bg-opacity) 50%,
      rgba(#f77638, $bg-opacity) 70%,
      rgba(#fec66d, $bg-opacity) 100%
    );
}

@mixin webkit-instagram-background($bg-opacity: 1) {
  background: -webkit-radial-gradient(
      circle farthest-corner at 35% 90%,
      rgba(#fec564, $bg-opacity),
      transparent 50%
    ),
    -webkit-radial-gradient(circle farthest-corner at 0 140%, rgba(#fec564, $bg-opacity), transparent
          50%),
    -webkit-radial-gradient(ellipse farthest-corner at 0 -25%, rgba(#5258cf, $bg-opacity), transparent
          50%),
    -webkit-radial-gradient(ellipse farthest-corner at 20% -50%, rgba(#5258cf, $bg-opacity), transparent
          50%),
    -webkit-radial-gradient(ellipse farthest-corner at 100% 0, rgba(#893dc2, $bg-opacity), transparent
          50%),
    -webkit-radial-gradient(ellipse farthest-corner at 60% -20%, rgba(#893dc2, $bg-opacity), transparent
          50%),
    -webkit-radial-gradient(ellipse farthest-corner at 100% 100%, rgba(#d9317a, $bg-opacity), transparent),
    -webkit-linear-gradient(rgba(#6559ca, $bg-opacity), rgba(#bc318f, $bg-opacity) 30%, rgba(
            #e33f5f,
            $bg-opacity
          )
          50%, rgba(#f77638, $bg-opacity) 70%, rgba(#fec66d, $bg-opacity) 100%);
}

@mixin breakpoint($point, $type: 'min') {
  $modifier: 0;

  @if $type == 'max' {
    $modifier: 1;
  }

  @media (#{$type}-width: $point - $modifier) {
    @content;
  }
}
