@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

$filter-bar-height: 64px;

.ghostPlaceHolder {
  display: none;
  @include breakpoint($breakpoint-small) {
    display: block;
    height: $filter-bar-height;
  }
}

.campaignFilters {
  height: $filter-bar-height;
  .content {
    padding: 0.5em 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.scrolled {
    @include breakpoint($breakpoint-small) {
      background-color: rgba(255, 255, 255, 0.85);
      backdrop-filter: blur(7px);
      border-bottom: 1px solid $card-border-color;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.075);
      position: fixed;
      left: 0;
      right: 0;
      z-index: 9;
      top: 0;
      animation: subtle-drop 0.2s;

      .content {
        padding: 0.5em 1em;
        max-width: $container-width;
        margin: 0 auto;
      }
    }

    @include breakpoint($breakpoint-medium) {
      .content {
        padding: 0.5em 3.5em;
      }
    }
  }
}

.scrolledTitle {
  display: none;
  @include breakpoint($breakpoint-small) {
    font-size: 0.75em;
    display: block;
  }
}

.leftCol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 50px;
}

.filterCol {
  min-height: 50px;
  display: flex;
  align-items: center;

  & + & {
    margin-left: 1.5em;
  }
}

@media (min-width: $breakpoint-small) {
  .campaignFilters {
    &.scrolled {
      left: $side-nav-width;
    }
  }
}
