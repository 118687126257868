// scss-lint:disable SelectorFormat

@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.wrap {
  position: relative;
}

.analyticsAppContent {
  // This should happen only once when app is ready first time
  animation: fade-in 0.3s;
}

.mobileNav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 102;

  border: none;
  background: transparent;
  // border-radius: 50%;
  width: $header-height-mobile - 4px;
  height: $header-height-mobile;
  display: flex;
  justify-content: center;
  align-items: center;

  .mobileNavIcon {
    width: 20px;
    height: 20px;
    fill: $brand-primary;
    &:active {
      opacity: 0.7;
    }
  }

  &.open {
    background: transparent;
    .mobileNavIcon {
      color: white;
      fill: white;
    }
  }
}

.sideBarCloseLayer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(#fff, 0.6);
  top: 0;
  bottom: -2px;
  opacity: 0;
  animation: fade-in 0.4s;
  animation-fill-mode: forwards;

  @include breakpoint($breakpoint-small) {
    display: none;
  }
}

@media (min-width: $breakpoint-small) {
  .wrap {
    padding-left: $side-nav-width;
  }

  .mobileNav {
    display: none;
  }
}

.campaignLoader {
  padding: 10vh 0;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  animation: fade-in 0.3s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}
