@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.filterInput {
  position: relative;
  z-index: 9;
}

.contentFilter {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.7em 1em;
  user-select: none;
  cursor: pointer;

  transition: background 0.1s, box-shadow 0.1s, border-color 0.1s;
  &:hover {
    background: #fff;
    box-shadow: $box-shadow;
  }

  &.open {
    color: #fff;
    background: $brand-primary;
    border-color: $brand-primary;
    box-shadow: $box-shadow;

    .filterIcon {
      transform: scaleY(-1);
      color: #fff;

      @include breakpoint($breakpoint-small, 'max') {
        top: 0px;
      }
    }
  }
}

.filterIcon {
  color: $brand-primary;
  margin-left: 9px;
  position: relative;
  display: inline-block;
  top: 2px;
  transition: transform 0.15s;
}
