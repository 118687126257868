@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.pageInfo {
  font-size: 1em;
  line-height: 1.5;
  padding: 0.75em 0 0;
  margin: 0 0 1.5em;
  color: $grey-1;
  font-weight: 500;
  text-align: center;

  animation: fade-in 0.4s;
}

.listSummary {
  font-size: 1em;
  line-height: 1.5;
  margin: 1.5em 0 0.75em;
  color: $grey-1;
  font-weight: 500;
  text-align: center;
}

.card {
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  overflow: hidden;
  background: #fff;
  // box-shadow: $box-shadow;

  padding: 0.5em 1.3em 0.9em;
  margin-bottom: 0.7em;
  min-height: 120px;

  &:not(.fullWidth) {
    max-width: 600px;
  }
}

.cardTitle {
  color: $brand-grey;
  font-weight: bold;
  margin: 0.5em 1em 1em;
}

.cardSubTitle {
  margin: 0em 1em 1.5em;
  font-size: 0.9em;
  color: $grey-2;
}

.user {
  display: flex;
  align-items: center;
}

.orderNumHeader {
  text-align: right;
}

.orderNum {
  font-weight: 600;
  color: $grey-1;
  font-size: 1em;

  letter-spacing: -0.03em;
  text-align: right;
}

.orderNumHeader,
.orderNum {
  min-width: 0.7rem;
  @include breakpoint($breakpoint-small) {
    min-width: 1.1rem;
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.channel {
  font-size: 0.85em;
  color: $grey-2;
  text-transform: capitalize;
}

.channelInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 15px;

  font-weight: 500;
  font-size: 0.9em;
  text-transform: capitalize;
  border-radius: 25px;
  min-width: 92px; // Instagram will be same width as FB and Twitter

  position: relative;

  .bg {
    background: #fff;
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 1;
    border-radius: 25px;
  }

  .text {
    position: relative;
    z-index: 2;
  }

  &.channel--instagram {
    color: $color-instagram;
    @include instagram-background(1);
  }

  &.channel--twitter {
    color: $color-twitter;
    background: $color-twitter;
  }

  &.channel--facebook {
    background: $color-facebook;
    color: $color-facebook;
  }

  &.channel--linkedin {
    background: $color-linkedin;
    color: $color-linkedin;
  }
}

.username {
  display: block;
  color: $brand-grey;
  font-weight: 600;
  max-width: 18rem;
  white-space: normal;
}

a.username {
  &:hover {
    color: $brand-primary;
  }
}

.restricted {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.value {
  color: $brand-grey;
  font-size: 1.25em;
}

.notAvailable {
  color: $light-grey-4;
  font-size: 1.25em;
}

.infoCode {
  margin: 0;
}
