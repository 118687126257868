@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.filterInput {
  position: relative;
  z-index: 9;
}

.contentFilter {
  .socialIcon {
    float: left;
    font-size: 1.5em;
    height: 1em;
    line-height: 1;
    margin-right: 0.6em;
    position: relative;
    top: -2px;
    width: 1em;
  }

  &:not(.open) {
    .icon-facebook {
      color: $color-facebook;
    }

    .icon-twitter {
      color: $color-twitter;
    }

    .icon-instagram {
      color: $color-instagram;
    }

    .icon-linkedin {
      color: $color-linkedin;
    }
  }
}

.dropdown {
  width: 275px;
  background: #fff;
  border: 1px solid #eee;

  padding: 0;
  border-radius: 5px;
  position: absolute;
  margin-top: 0.5em;
  box-shadow: $box-shadow-1;
  animation: subtle-drop 0.2s;
}

.label {
  display: flex;
  font-size: 1.1em;
  padding: 1em;
  user-select: none;
  cursor: pointer;
  user-select: none;

  &.selected {
    color: $brand-primary;
  }

  &.summaryLabel {
    border-bottom: 1px solid #eee;
    padding: 1em;
    .selected & {
      color: $brand-primary;
    }
  }

  &:hover {
    background: $light-grey-1;
  }

  input {
    margin-right: 1em;
    top: -2px;
    position: relative;
    display: none;
  }

  .inputHelp {
    color: $grey-2;
    margin-top: 3px;
    font-size: 0.8em;
    display: block;
  }
}

.actionButton {
  color: $brand-primary;
  font-weight: 600;
  float: right;
  cursor: pointer;

  border: none;
  font-size: 1em;
  background: transparent;

  &:active,
  &:hover {
    text-decoration: underline;
  }
}
