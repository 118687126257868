@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.postImageWrap {
  flex: 1.9 0 0;

  $max-posts-has-animation: 20;
  opacity: 0;

  transform-origin: 50% 0;
  animation-name: subtle-drop;
  animation-timing-function: $cubic-bezier;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-delay: 0;

  @for $i from 1 through $max-posts-has-animation {
    .postWithImage:nth-child(#{$i}) & {
      animation-delay: $i * (150ms) - ($i * 4);
    }
  }

  line-height: 0;
  text-align: center;

  @include breakpoint($breakpoint-small) {
    text-align: left;
    padding: 0 0 0 1.5em;
  }
}

.postImageLink {
  width: 100%;
  position: relative;
  z-index: 3;

  display: inline-block;
  background-color: #fff;

  overflow: hidden;

  @media print {
    max-height: 400px;
    margin-bottom: 10px;
  }

  @include breakpoint($breakpoint-small) {
    top: -1.5em;
    width: 220px;
    height: 220px;
    border-radius: $card-border-radius - 2px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.075);
    display: block;
    transition: all 0.15s;

    &:hover {
      transform: translate3d(0, -2px, 0);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.125);
    }
  }

  .postFullImage {
    display: none;
    @media print {
      width: 100%;
      display: block;
    }
  }
}

.postImage {
  height: 100%;

  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  z-index: 2;
  min-height: 80vmin;

  @include breakpoint($breakpoint-small) {
    display: block;
    min-height: 0;
  }
}

.postVideoWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.postVideo {
  height: auto;
  z-index: 2;
  width: 100%;
  position: relative;
}

.playIcon {
  color: #fff;
  font-size: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1.5rem 0 0 -1.5rem;
  width: 3rem;
  height: 3rem;
  z-index: 3;

  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.15));
}

.postImageFallback {
  background: transparent;

  .image--facebook & {
    color: lighten($color-facebook, 20%);
  }

  .image--instagram & {
    color: lighten($color-instagram, 25%);
  }

  .image--twitter & {
    color: lighten($color-twitter, 20%);
  }

  .image--linkedin & {
    color: lighten($color-linkedin, 20%);
  }

  display: none;

  @include breakpoint($breakpoint-small) {
    background: #fefefe;
    display: block;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.postImageFallbackLogo {
  // center
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -30px;

  display: inline-block;
  width: 60px;
  height: 60px;
}
