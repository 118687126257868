@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.block {
  margin-bottom: 1rem;
}

.infoIcon {
  color: rgba($brand-grey, 0.4);
  cursor: help;

  &:hover {
    color: rgba($brand-grey, 0.8);
  }
}

// Keep style same because clients may see it in demos
.warningIcon {
  color: rgba($brand-grey, 0.4);
  cursor: help;

  &:hover {
    color: rgba($brand-grey, 0.8);
  }
}
