@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.userProfile {
  animation: fade-in 0.4s;
}

.introduction {
  text-align: center;
  animation: subtle-drop 0.5s;
}

.username {
  font-size: 1.6rem;
  font-weight: 600;
  color: $brand-grey;
  margin-top: 0.5rem;
}

.fullname {
  font-size: 0.9rem;
  font-weight: 500;
  color: $card-title-color;
  margin-top: 0.15rem;
}

.channel {
  text-align: center;
  margin: 20px 0;
}

.channelLink {
  display: inline-block;
}

.kpis {
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem;
  justify-content: center;
}

.kpi {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 0 2rem;
  font-size: 0.8rem;
  color: $card-title-color;
  font-weight: 500;
  text-align: center;

  border-right: 1px solid rgba(0, 0, 0, 0.075);
  &:last-child {
    border: none;
  }

  @include breakpoint($breakpoint-small) {
    padding: 0 4rem;
  }
}

.kpiValue {
  font-size: 1.6rem;
  font-weight: 600;
  color: $brand-grey;
  margin-bottom: 0.25rem;
}
