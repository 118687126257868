@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.card {
  background: #fff;
  border: $card-border-width solid $card-border-color;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  overflow: hidden;

  padding: 1em;
}

.cardHeader {
  padding: 0.25em 0.25em 0.5em;
}

.cardTitle {
  color: $brand-grey;
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 0.35em;
}

.cardSubTitle {
  margin: 0em;
  font-size: 1em;
  color: $grey-2;
}

.cardContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -0.75em -0.25em;
  min-height: $chart-height-mobile;

  @include breakpoint($breakpoint-small) {
    margin: 0 0em -0.25em;
    min-height: $chart-height;
  }
}

@media print {
  .card {
    box-shadow: none;
  }
}
