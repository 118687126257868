@import 'styles/variables.scss';
@import 'styles/mixins.scss';
@import 'styles/animations.scss';

.link {
  color: $brand-grey;
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  margin: -5px 0;
  padding: 5px 0;

  transition: color 0.1s;

  &:hover {
    color: $brand-primary;
  }
}

.arrowButtonIcon {
  display: inline-block;
  width: 28px;
  height: 28px;
  float: right;
  margin-left: 0px;
  position: relative;
  color: $brand-grey;
  top: -4px;

  @include breakpoint($breakpoint-small) {
    top: -2px;
  }

  svg {
    width: 28px;
    height: 28px;
  }
}

.hoverAction {
  display: inline-block;
  padding-right: 2em;

  .showOnHover {
    display: none;

    transform-origin: 0 50%;
    opacity: 0;
    transform: translate3d(-5px, 0, 0);
    margin: 0 0 0 10px;

    .showOnHoverText {
      font-size: 0.65em;
      font-weight: 600;
    }
  }

  .link:hover & {
    .arrowButtonIcon {
      display: none;
    }
    .showOnHover {
      text-decoration: none;
      display: inline-block;
      opacity: 1;
      animation: subtle-come-from-left 0.3s;
      transform: translate3d(0, 0, 0);
      .arrowButtonIcon {
        display: inline-block;
        color: $brand-primary;
      }
    }
  }
}
