@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.wrap {
  position: relative;
  color: #fff;
  height: 2.35rem;

  display: flex;
  align-items: center;
}

.selected {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 1.15rem;
}

.selectedLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: $side-nav-width - 4.5rem;
}

.select {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  opacity: 0;

  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  display: block;

  color: #fff;
  border: none;

  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;
  width: 100%;

  option {
    border-radius: 0;
    color: $dark-grey;
    padding: 0;
  }
}

.dropdownIcon {
  height: 0.9rem;
  width: auto;
  margin-left: 0.5rem;
}
