$color-blue: #0069e2;
$color-green: #24b699;
$color-pink: #f65680;
$color-dark: #001c2d;

$grey-1: #808593;
$grey-2: #666;
$grey-3: #333;
$grey-4: #37474f;

// # Colors
$brand-primary: #0069e2;
$brand-success: #24b699;
$brand-accent: #f65680;
$brand-grey: #475061;

$dark-grey: #001c2d;

$app-name-color: #8ffdc7;

$color-success: $brand-success;
$color-danger: orange;
$color-alert: red;
$color-grey: #808593;

$light-grey-1: #f9f9fb;
$light-grey-2: #ddd;
$light-grey-3: #c9cedb;
$light-grey-4: #b8bbc4;

$ghost-white: #f8fafd;

$color-twitter: #000;
$color-instagram: #ae3287;
$color-facebook-old: #395693;
$color-facebook: #1977f3;
$color-linkedin: #0b66c2;
$color-youtube: #ff0000;

// # box-shadow
$box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06);
$box-shadow-1: 0 8px 16px rgba(0, 0, 0, 0.06);
$box-shadow-2: 0 12px 20px rgba(0, 0, 0, 0.1);

// # Font
$font-family-sans-serif: 'Avenir-Next', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Noto Color Emoji',
  'Segoe UI Symbol';
$base-font-size-mobile: 12px;
$base-font-size: 14px;
$base-font-size-large: 16px;

// # Elements
$header-height: 68px;
$header-height-mobile: 52px;
$container-width: 87.5rem;
$border-radius: 4px;
$button-border-radius: 4px;
$input-border-radius: 4px;
$side-nav-width: 22rem;

$card-border-width: 1px;
$card-border-color: #eceff1;
$card-border-radius: 6px;
$card-box-shadow: 0 9px 18px rgba(0, 0, 0, 0.0275);
$card-title-color: lighten($brand-grey, 15%);
$card-header-height: 3.25rem;

// # Breakpoints
$breakpoint-small: 768px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1919px;

// # Animation
$cubic-bezier: cubic-bezier(0.6, 0.44, 0.37, 0.87);

// # Chart
$chart-height: 320px;
$chart-height-mobile: 200px;
$pie-chart-data-color1: $brand-primary;
$pie-chart-data-color2: #5b9beb;
$pie-chart-data-color3: #a9ccf5;
