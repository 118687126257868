@import 'styles/variables.scss';
@import 'styles/animations.scss';

.kpiList {
  display: flex;
  flex-wrap: wrap;

  margin: 0 -0.75em;
}

.kpiCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0 0.75em 1em;
  padding: 1.75em;

  flex: 1 0 0;
  // height: 170px;
  overflow: hidden;
  background: #fff;

  border-radius: $card-border-radius;
  overflow: hidden;
  background: #fff;
  border: $card-border-width solid $card-border-color;
  box-shadow: $card-box-shadow;
}

.kpiIcon {
  animation: scale-in 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $ghost-white;
  background: lighten($brand-primary, 53%);
  background: #f8f8f8;
  width: 60px;
  height: 60px;
  font-size: 20px;
  margin: 0em 0 0.5em;

  color: $brand-success;
}

.kpiValue {
  font-size: 2.8em;
  font-weight: 600;
  line-height: 1;
  color: $brand-grey;
  margin-bottom: 0.1em;
  min-height: 1em;
}

.kpiLabel {
  font-weight: 400;
  color: $brand-grey;
}

.kpiImage {
  height: 150px;
  background-size: cover;
}
